/*
 * Copyright 2024 VMware, Inc.
 * All rights reserved.
 */

import { inject } from '@angular/core';
import { ON_LOCALE_BUNDLE_LOADED } from '@dpa/ui-common';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { LocaleBundleId } from '@ws1c/intelligence-core/const';

/**
 * Makes sure locale bundles are loaded before accessing a route
 * @param {string} i18nKey
 * @export
 * @returns {Function}
 */
export function i18nResourceLoadGuard(i18nKey: LocaleBundleId): () => Observable<boolean> {
  return function (): Observable<boolean> {
    const translationsLoaded$: BehaviorSubject<Set<string>> = inject(ON_LOCALE_BUNDLE_LOADED);
    return translationsLoaded$.pipe(
      filter((loadedTranslations: Set<string>) => loadedTranslations.has(i18nKey)),
      map(() => true),
    );
  };
}
